<mat-toolbar style="background-color: #003f63;">
  <button mat-icon-button (click)="backHome()">
    <mat-icon style="color: white">arrow_back</mat-icon>
  </button>
  <span style="margin-left: 10px;color: white"><span style="color: rgb(140, 157, 179);">Gerenciar Módulos </span> <span
      style="color: #3399ff;">•</span>
    {{modulesService.pageName}}</span>
  <span class="example-spacer"></span>
</mat-toolbar>

<mat-grid-list *ngIf="modulesService.modules" [cols]="columns()" [rowHeight]="gridTile()">
  <mat-grid-tile *ngFor="let m of modulesService.modules">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <div style="padding: 0 20px; text-align: center;">
        <span class="module-name">{{m.title}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <img src="{{m.image}}" style="width:80%">
        <br>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
          <button mat-icon-button matTooltip="{{m.description}}" (click)="showInfo(m.title, m.description)">
            <mat-icon>info</mat-icon>
          </button>
          <button mat-icon-button [matTooltip]="m.isActive ? 'Desativar Módulo' : 'Adicionar Módulo'"
            [ngStyle]="{'color' : m.isActive ? '#B3312E' : 'black' }"
            (click)="m.isActive ? cancelModule(m) : addModule(m)">
            <mat-icon>{{m.isActive ? 'cancel' : 'add' }}</mat-icon>
          </button>

          <mat-form-field class="example-full-width" appearance="fill">
            <input matInput #ref [matDatepicker]="picker" [min]="minDate" [max]="maxDate"
              (dateChange)="dateChange($event, m)">
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <button mat-icon-button matTooltip="Escolher duração do teste" (click)="picker.open()"
            [disabled]="m.isActive">
            <mat-icon>av_timer</mat-icon>
          </button>

        </div>
      </div>
      <!-- <span class="module-date">A ser desativado em 94 dias</span> -->
      <mat-chip-list>
        <mat-chip [ngStyle]="{'background-color' : (m.isActive) ? (m.isTest ? '#3399ff' : '#81D977') : '#B3312E' }"
          style="color: white">
          {{m.isActive ? (m.isTest ? 'Em Teste' : 'Ativo') : 'Inativo'}}
        </mat-chip>
      </mat-chip-list>
    </div>
  </mat-grid-tile>
</mat-grid-list>
