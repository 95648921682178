import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit, HostListener } from "@angular/core";
import { Page } from "src/app/shared/interfaces/page";
import { PageService } from "src/app/shared/_services/page/page.service";
import { AlertService } from "src/app/shared/dialogs/alert/alert.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { GetAddressByCepService } from "src/app/shared/_services/getAddressByCep/get-address-by-cep.service";
import { PageValidators } from "../../../shared/validators/page";
@Component({
  selector: "app-register-page-owner",
  templateUrl: "./register-page-owner.component.html",
  styleUrls: ["./register-page-owner.component.scss"],
})
export class RegisterPageOwnerComponent implements OnInit {
  public page: Page = {};
  public street: String;
  public number: String;
  public innerWidth;

  constructor(
    private router: Router,
    private pageService: PageService,
    private alertService: AlertService,
    private snackbar: MatSnackBar,
    private getAddressService: GetAddressByCepService,
    public pageVal: PageValidators
  ) {
    this.innerWidth = window.innerWidth;
    this.page = this.pageService.page;
    this.page.address = {};
  }

  ngOnInit(): void {}

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  previousForm() {
    this.router.navigate(["register-page/establishment"]);
  }

  getAddress() {
    this.getAddressService
      .getAddressByCep(this.pageService.page.address.postalCode)
      .then((res: any) => {
        res.json().then((res) => {
          this.street = res.logradouro;
          this.pageService.page.address.city = res.localidade;
          this.pageService.page.address.district = res.bairro;
          this.pageService.page.address.state = this.getAddressService.ufToState(
            res.uf
          );
        });
      });
  }

  submit() {
    if (
      this.pageVal.nameFormControl.invalid ||
      this.pageVal.cpfFormControl.invalid ||
      this.pageVal.birthDateFormControl.invalid ||
      this.pageVal.postalCodeFormControl.invalid ||
      this.pageVal.streetFormControl.invalid ||
      this.pageVal.numberFormControl.invalid ||
      this.pageVal.districtFormControl.invalid ||
      this.pageVal.cityFormControl.invalid ||
      this.pageVal.stateFormControl.invalid
    ) {
      this.snackbar.open("Complete os campos corretamente", "Fechar", {
        duration: 3000,
      });
    } else {
      this.pageService.page.address.street = this.street;
      this.pageService.page.address.number = this.number;
      console.log(this.page);
      this.pageService.createPage().subscribe(
        (res: any) => {
          console.log(res);
          if (res.success == true) {
            this.pageService.uploadImage(res.info.pageId).subscribe(
              (res: any) => {
                if (res.success == true) {
                  this.snackbar.open(
                    "Página cadastrada com sucesso",
                    "Fechar",
                    {
                      duration: 3000,
                    }
                  );
                  this.pageService.page = {};
                  this.router.navigate([""]);
                } else {
                  console.log(res);
                  this.alertService.showAlert("Erro", "Sua página foi cadastrada, mas não conseguimos atualizar sua foto.");
                  this.router.navigate([""]);
                }
              },
              (err) => console.log(err)
            );
          } else {
            window.scrollTo(500, 0);
            this.alertService.showAlert("Erro", res.message);
          }
        },
        (err) => console.log(err)
      );
    }
  }
}
