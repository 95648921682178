import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { Page } from '../../interfaces/page';
import { PageService } from '../../_services/page/page.service';
import { PartnerService } from '../../_services/partner/partner.service';
import { AlertService } from '../alert/alert.service';

@Component({
    templateUrl: './page-info.component.html',
    styleUrls: ['./page-info.component.scss']
})
export class PageInfoComponent implements OnInit {
    
    public page: any;
    public configs: Page = {};
    pageInfoForm: FormGroup;
    http: HttpClient;
    slideStatus = false;
    showButton : boolean;
    values = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        public dialog: MatDialogRef<PageInfoComponent>,
        private pageService: PageService,
        private partnerService: PartnerService,
        private alertService: AlertService,
        private formBuilder: FormBuilder
        ) {}

    ngOnInit(): void {
        this.page = this.data.page;
        
        this.pageInfoForm = this.formBuilder.group({
            name: [this.page.page_name, [Validators.required]],
            nameId: [this.page.page_name_id, [Validators.required]],
            pageActive: [this.page.page.establishment.sign.active]
        });
        this.showButton = this.partnerService.partner?.config.manage_activation;
        this.values = this.page.page_name_id;
    }   

    save(submitButton: MatButton) {
        
        submitButton.disabled = true;
        
        const name = this.pageInfoForm.get('name').value;
        const nameId = this.pageInfoForm.get('nameId').value;

        const id = this.page._id;
        this.pageService
            .updateNameAndNameId(name, nameId, id)
            .subscribe(
                (res: any) => {
                    if (res.success) {
                        this.alertService.showAlert(
                            "Sucesso!",
                            "Os dados foram atualizados com sucesso!"
                        );
                        this.dialog.close({name, nameId})   
                    } else {
                        submitButton.disabled = false;
                        this.alertService.showAlert(
                            "Erro",
                            res.message
                        );
                    }
                },
                err => console.log(err)
            );
    }

    btnBlockInblock(event){
        this.slideStatus = true;
        const pageId = this.page._id;
        if(event.checked){
            this.activePage(pageId)
        } else{
            this.blockPage(pageId)
        }
    }

    activePage(pageId){
        this.pageService.activePage(pageId)
            .pipe(finalize(() => this.slideStatus = false))
            .subscribe((res:any)=>{
                if(res.success){
                    this.alertService.showAlert(
                        'Sucesso!',
                        res.message
                    ); 
                    this.page.establishment.sign.active = true;  
                }else {
                    this.alertService.showAlert(
                        "Erro",
                        res.message
                    );
                }
                
            }) 
    }

    blockPage(pageId){
        this.pageService.blockPage(pageId)
            .pipe(finalize(() => this.slideStatus = false))
            .subscribe((res:any)=>{
                if(res.success){
                    this.alertService.showAlert(
                        'Sucesso!',
                        res.message
                    ); 
                    this.page.establishment.sign.active = false; 
                }else {
                    this.alertService.showAlert(
                        "Erro",
                        res.message
                    );
                }
                
            }) 
    }
    
    copyValue(event: any) {
        this.values = event.target.value;
    }
}