import { FormControl, Validators, FormGroup } from "@angular/forms";
import { CpfCnpjValidator } from "src/app/shared/validators/cpf-cnpj.validator";

export class PartnerValidators {
  nameFormControl = new FormControl("", [Validators.required]);

  technicalManagerFormControl = new FormControl("", [Validators.required]);

  establishmentNameFormControl = new FormControl("", [Validators.required]);

  logoFormControl = new FormControl("", [Validators.required]);

  phoneFormControl = new FormControl("", [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(11),
  ]);

  contactSupportFormControl = new FormControl("", [
    Validators.required,
  ]);

  cnpjFormControl = new FormControl('', [
    CpfCnpjValidator.validCpfCnpj()
  ]);

  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl = new FormControl("", [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(16),
  ]);

  public getNameErrorMessage() {
    return this.nameFormControl.hasError("required")
      ? "Este campo é obrigatório"
      : "";
  }

  public getTechnicalManagerErrorMessage() {
    return this.technicalManagerFormControl.hasError("required")
      ? "Este campo é obrigatório"
      : "";
  }

  public getPhoneErrorMessage() {
    return this.phoneFormControl.hasError("required")
      ? "Este campo é obrigatório"
      : this.phoneFormControl.hasError("minlength")
      ? "Há números faltando"
      : this.phoneFormControl.hasError("maxlength")
      ? "Há números demais"
      : "";
  }

  public getPhoneSupportErrorMessage() {
    return this.contactSupportFormControl.hasError("required")
      ? "Este campo é obrigatório"
      : "";
  }

  public getEmailErrorMessage() {
    return this.emailFormControl.hasError("required")
      ? "Este campo é obrigatório"
      : this.emailFormControl.hasError("email")
      ? "Email inválido"
      : "";
  }

  public getCnpjErrorMessage() {
    return this.cnpjFormControl.hasError('minlength')
      ? 'Há números faltando'
      : this.cnpjFormControl.hasError('maxlength')
      ? 'Há números demais'
      : 'CNPJ inválido';
  }
  
  public getPasswordErrorMessage() {
    return this.passwordFormControl.hasError("required")
      ? "Este campo é obrigatório"
      : this.passwordFormControl.hasError("minlength")
      ? "Mínimo 6 caracteres"
      : this.passwordFormControl.hasError("maxlength")
      ? "Máximo 16 caracteres"
      : "";
  }
}
