import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Page } from "../../interfaces/page";
import { ObjectID } from 'mongodb';

@Injectable({
  providedIn: "root",
})
export class PageService {
  public page: Page = {};
  
  public image = "../../../../assets/fastmeal-icon.svg";
  public imageFile;

  public url = environment.url + "/partnerauth/grf-integration/";

  constructor(
    private http: HttpClient,
  ) {}

  checkEmailUsername(_username, _email) {
    let url = this.url + "check";
    return this.http.post(url, { username: _username, email: _email });
  }

  uploadImage(page_id) {
    let url = environment.url + "/partnerauth/page/uploadImg/";
    let imgFile = this.imageFile;
    const formData = new FormData();
    formData.append('image', imgFile);
    formData.append('pageId', page_id);
    console.log(formData)
    return this.http.post(url, formData);
  }

  updateNameAndNameId(name: string, nameId: string, id: ObjectID) {
    let url = environment.url + '/partnerauth/page/updatenamenameid/' + id;
    return this.http.patch(url, { name, nameId });
  }

  createPage() {
    let url = this.url;
    return this.http.post(url, this.page);
  }

  blockPage(id){
    return this.http.put(`${environment.url}/partnerauth/partner/block/${id}`, null);
  }
  
  activePage(id){
    return this.http.put(`${environment.url}/partnerauth/partner/active/${id}`, null);
  }
}
