<mat-toolbar>
  <mat-toolbar-row>
    <span class="title">Registrar Parceiro</span>
    <span class="spacer"></span>
    <button mat-button *ngIf="innerWidth > 950" class="sign-button" disabled>
      Anota AI
    </button>

  </mat-toolbar-row>
</mat-toolbar>
<div fxLayout="row" *ngIf="innerWidth > 950" fxFill>
  <div fxLayout="column" fxLayoutAlign="center center" fxFlex="60%">
    <img *ngIf="innerWidth >= 950" class="register-image" src="../../assets/logo-register.svg">
  </div>
  <div fxLayoutAlign="start center" fxFlex="40%">
    <mat-card class="register-card">
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="register-card-title">
          <span><b>Cadastre um sistema</b></span>
          <span><b>integrador!</b></span>

        </div>
        <div class="form-div" fxLayout="column" fxLayoutAlign="center center">
          <form class="register-form">
            <mat-form-field class="register-field">
              <mat-label class="register-label">Sistema Integrador</mat-label>
              <input matInput class="register-field-line" [(ngModel)]="partner.name" name="name"
                [formControl]="partnerVal.nameFormControl">
              <mat-error *ngIf="partnerVal.nameFormControl.invalid">{{partnerVal.getNameErrorMessage()}}</mat-error>
            </mat-form-field>

            <mat-form-field class="register-field">
              <mat-label class="register-label">Responsável Técnico</mat-label>
              <input matInput class="register-field-line" [(ngModel)]="partner.technical_manager"
                name="technical_manager" [formControl]="partnerVal.technicalManagerFormControl">
              <mat-error *ngIf="partnerVal.technicalManagerFormControl.invalid">
                {{partnerVal.getTechnicalManagerErrorMessage()}}</mat-error>
            </mat-form-field>

            <mat-form-field class="register-field">
              <mat-label class="register-label">Telefone</mat-label>
              <input matInput class="register-field-line" [(ngModel)]="partner.phone" name="phone"
                mask="(00) 0000-0000||(00) 00000-0000" [formControl]="partnerVal.phoneFormControl">
              <mat-error *ngIf="partnerVal.phoneFormControl.invalid">
                {{partnerVal.getPhoneErrorMessage()}}</mat-error>
            </mat-form-field>

            <mat-form-field class="register-field">
              <mat-label class="register-label">Email</mat-label>
              <input matInput class="register-field-line" [(ngModel)]="partner.email" name="email"
                [formControl]="partnerVal.emailFormControl">
              <mat-error *ngIf="partnerVal.emailFormControl.invalid">
                {{partnerVal.getEmailErrorMessage()}}</mat-error>
            </mat-form-field>

            <mat-form-field class="register-field">
              <mat-label class="register-label">Senha</mat-label>
              <input matInput class="register-field-line" type="password" [(ngModel)]="partner.password" name="password"
                [formControl]="partnerVal.passwordFormControl">
              <mat-error *ngIf="partnerVal.passwordFormControl.invalid">
                {{partnerVal.getPasswordErrorMessage()}}</mat-error>
            </mat-form-field>
          </form>
        </div>
        <br>
        <div fxLayout="row" fxLayoutAlign="start center">
          <button mat-button class="register-card-button" (click)="registerPartner()">Cadastrar</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<!-- -------CELLPHONE------- -->

<div *ngIf="innerWidth <= 950" fxLayout="column" fxLayoutAlign="center center"
  style="background-color: #14243b; min-height: 100%!important">
  <img style="width: 200px; margin-top: 25px; margin-bottom: 25px;" src="../../assets/white-logo.svg">
  <mat-card class="register-card">
    <mat-card-content>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="register-card-title">
        <span><b>Cadastre um sistema</b></span>
        <span><b>integrador!</b></span>

      </div>
      <div class="form-div" fxLayout="column" fxLayoutAlign="center center">
        <form class="register-form">
          <mat-form-field class="register-field">
            <mat-label class="register-label">Sistema Integrador</mat-label>
            <input matInput class="register-field-line" [(ngModel)]="partner.name" name="name"
              [formControl]="partnerVal.nameFormControl">
            <mat-error *ngIf="partnerVal.nameFormControl.invalid">{{partnerVal.getNameErrorMessage()}}</mat-error>
          </mat-form-field>

          <mat-form-field class="register-field">
            <mat-label class="register-label">Responsável Técnico</mat-label>
            <input matInput class="register-field-line" [(ngModel)]="partner.technical_manager" name="technical_manager"
              [formControl]="partnerVal.technicalManagerFormControl">
            <mat-error *ngIf="partnerVal.technicalManagerFormControl.invalid">
              {{partnerVal.getTechnicalManagerErrorMessage()}}</mat-error>
          </mat-form-field>

          <mat-form-field class="register-field">
            <mat-label class="register-label">Telefone</mat-label>
            <input matInput class="register-field-line" [(ngModel)]="partner.phone" type="number" name="phone"
              mask="(00) 90000-0000" [formControl]="partnerVal.phoneFormControl">
            <mat-error *ngIf="partnerVal.phoneFormControl.invalid">
              {{partnerVal.getPhoneErrorMessage()}}</mat-error>
          </mat-form-field>

          <mat-form-field class="register-field">
            <mat-label class="register-label">Email</mat-label>
            <input matInput class="register-field-line" [(ngModel)]="partner.email" name="email"
              [formControl]="partnerVal.emailFormControl">
            <mat-error *ngIf="partnerVal.emailFormControl.invalid">
              {{partnerVal.getEmailErrorMessage()}}</mat-error>
          </mat-form-field>

          <mat-form-field class="register-field">
            <mat-label class="register-label">Senha</mat-label>
            <input matInput class="register-field-line" type="password" [(ngModel)]="partner.password" name="password"
              [formControl]="partnerVal.passwordFormControl">
            <mat-error *ngIf="partnerVal.passwordFormControl.invalid">
              {{partnerVal.getPasswordErrorMessage()}}</mat-error>
          </mat-form-field>
        </form>
      </div>
      <br>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button mat-button class="register-card-button" (click)="registerPartner()">Cadastrar</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
