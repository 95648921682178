import { TokenInterceptorService } from "./shared/_services/token-interceptor/token-interceptor.service";
import { MainComponent } from "./partner/main/main.component";
import { LoginComponent } from "./partner/login/login.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  HTTPListener,
  HTTPStatus,
} from "./shared/_services/interceptor/interceptor.service";
const RxJS_Services = [HTTPListener, HTTPStatus];

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SignerMainComponent } from "./signer/signer-main/signer-main.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatRadioModule } from "@angular/material/radio";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatListModule } from "@angular/material/list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatMenuModule } from "@angular/material/menu";
import { FlexLayoutModule } from "@angular/flex-layout";
import { JwtModule } from "@auth0/angular-jwt";
import { NotAuthorizedComponent } from "./signer/not-authorized/not-authorized.component";
import { LoaderComponent } from "./shared/loader/loader.component";
import { DeleteConfirmationComponent } from "./shared/dialogs/delete-confirmation/delete-confirmation.component";
import { AlertComponent } from "./shared/dialogs/alert/alert.component";
import { EditPartnerComponent } from "./shared/dialogs/edit-partner/edit-partner.component";
import { NotFoundComponent } from "./shared/components/not-found/not-found.component";
import { SpeedDialComponent } from "./shared/components/speed-dial/speed-dial.component";
import { RegisterPageComponent } from "./partner/register-page/register-page.component";
import { RegisterPageEstablishmentComponent } from "./partner/register-page/register-page-establishment/register-page-establishment.component";
import { RegisterPageOwnerComponent } from "./partner/register-page/register-page-owner/register-page-owner.component";
import { PlanCardComponent } from "./shared/components/plan-card/plan-card.component";
import { PlansComponent } from "./partner/plans/plans.component";
import { PageValidators } from "./shared/validators/page";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { ImageCropperModule } from "ngx-image-cropper";
import { ImgCropperComponent } from "./shared/dialogs/img-cropper/img-cropper.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { PartnerValidators } from "./shared/validators/partner";
import { ModulesComponent } from "./partner/modules/modules.component";
import { MatNativeDateModule } from "@angular/material/core";
import { MatChipsModule } from "@angular/material/chips";
import { MatCheckboxModule } from '@angular/material/checkbox';

import { EstablishmentConfigsComponent } from "./shared/dialogs/establishment-configs/establishment-configs.component";
import { PageInfoComponent } from './shared/dialogs/page-info/page-info.component';
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    SignerMainComponent,
    NotAuthorizedComponent,
    LoaderComponent,
    DeleteConfirmationComponent,
    AlertComponent,
    EditPartnerComponent,
    NotFoundComponent,
    SpeedDialComponent,
    RegisterPageComponent,
    RegisterPageEstablishmentComponent,
    RegisterPageOwnerComponent,
    PlanCardComponent,
    PlansComponent,
    ImgCropperComponent,
    ModulesComponent,
    EstablishmentConfigsComponent,
    PageInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatGridListModule,
    MatButtonModule,
    FlexLayoutModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatListModule,
    MatTooltipModule,
    MatStepperModule,
    MatExpansionModule,
    MatMenuModule,
    ReactiveFormsModule,
    ImageCropperModule,
    ClipboardModule,
    NgxMaskModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem("access_token");
        },
        whitelistedDomains: [
          "https://app.anota.ai",
          "https://stagingchatbot.herokuapp.com/",
        ],
      },
    }),
    InfiniteScrollModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
  ],
  providers: [
    RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    PageValidators,
    PartnerValidators,
    MatDatepickerModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
