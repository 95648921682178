<div fxLayout="row" fxFill>
  <div *ngIf="innerWidth > 950" fxLayout="col" fxLayoutAlign="center center" fxFlex="60%">
    <img class="login-image" src="../../assets/logo-animate.svg">
    <div class="eyes" fxLayout="row">
      <div class="eye">
        <div class="ball"></div>
      </div>
      <div class="eye">
        <div class="ball"></div>
      </div>
    </div>
  </div>
  <div *ngIf="innerWidth > 950" class="login-content" fxFlex="40%" fxFlexFill>
    <div class="login-toolbar" fxLayout="row" fxLayoutAlign="center center">
      <span class="login-toolbar-title">Acesse sua conta</span>
    </div>
    <div class="form-div" fxLayout="col" fxLayoutAlign="center center">
      <form class="login-form">
        <mat-form-field class="login-field">
          <mat-label class="login-label">Email</mat-label>
          <input matInput class="login-field-line" type="email" [(ngModel)]="credentials.email" name="email">
        </mat-form-field>

        <mat-form-field class="login-field">
          <mat-label class="login-label">Senha</mat-label>
          <input matInput class="login-field-line" type="password" [(ngModel)]="credentials.password" name="password">
        </mat-form-field>
      </form>
    </div>
    <button mat-flat-button flex="33" class="sign-in-button" (click)="login()">Entrar</button>
    <br>
    <button mat-button class="login-help-button" (click)="showHelp()">PROBLEMAS PARA FAZER LOGIN?</button>
  </div>

  <!-- --------------CELL PHONE------------------- -->

  <div *ngIf="innerWidth <= 950" class="login-phone-content" fxFlexFill>
    <div class="login-toolbar" fxLayout="row" fxLayoutAlign="center center">
      <span class="login-toolbar-title">Acesse sua conta</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <img style="width: 200px; margin-top: 25px;" src="../../assets/white-logo.svg">
    </div>
    <div class="form-div" fxLayout="col" fxLayoutAlign="center center">
      <form class="login-form">
        <mat-form-field class="login-field">
          <mat-label class="login-label">Email</mat-label>
          <input matInput class="login-field-line" type="email" [(ngModel)]="credentials.email" name="email">
        </mat-form-field>

        <mat-form-field class="login-field">
          <mat-label class="login-label">Senha</mat-label>
          <input matInput class="login-field-line" type="password" [(ngModel)]="credentials.password" name="password">
        </mat-form-field>
      </form>
    </div>
    <button mat-flat-button flex="33" class="sign-in-button" (click)="login()">Entrar</button>
    <br>
    <button mat-button class="login-help-button" (click)="showHelp()">PROBLEMAS PARA FAZER LOGIN?</button>
  </div>

</div>
