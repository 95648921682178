<div *ngIf="innerWidth > 950" fxFill>
  <!-- EDIT ESTABLISHMENT DRAWER -->
  <mat-drawer-container class="example-container" fxFill>
    <mat-drawer #drawer class="edit-drawer" mode="over" position="end">
      <div *ngIf="!accessKeyChecked" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" fxFill>
        <mat-form-field class="access-key-form-field" *ngIf="establishmentObj != undefined">
          <mat-label class="light-blue">Digite o Token do Estabelecimento:</mat-label>
          <input matInput [(ngModel)]="establishmentObj.page_token" class="dark-blue">
        </mat-form-field>
        <button mat-button class="confirm-button" class="button" (click)="accessKeyCheck()">Confirmar</button>
      </div>
      <div *ngIf="accessKeyChecked">
        <div class="edit-drawer-toolbar" *ngIf="establishmentObj.page != undefined"
          [style.background-color]="establishmentObj.page.establishment.sign.active || !establishmentObj.page.establishment.isGRFood ? '#003f63' : '#B3312E'"
          fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
          <div fxLayoutAlign="end end">
            <div class="avatar-circle" fxLayout="column" fxLayoutAlign="center center">
              <img class="full-height" *ngIf="!establishmentObj.page.image" src="../../../assets/fastmeal-icon.svg">
              <img class="full-height" *ngIf="establishmentObj.page.image" src="{{establishmentObj.page.image}}">
            </div>
            <button (click)="fileInput.click()" class="upload-button" mat-mini-fab>
              <mat-icon>insert_photo</mat-icon>
            </button>
            <input hidden type="file" (change)="fileChangeEvent($event)" #fileInput id="file">
          </div>
          <mat-form-field *ngIf="establishmentObj != undefined" appearance="fill" class="establishment-name-field">
            <div class="align-center">
              <input matInput placeholder="{{establishmentObj.page_name}}" name="page_name"
                [(ngModel)]="establishmentObj.page_name" readonly>
            </div>
          </mat-form-field>
        </div>
        <div class="edit-drawer-content" fxLayout="column" fxLayoutAlign="center center">
          <div class="form-div" fxLayout="column" fxLayoutAlign="center center">
            <form *ngIf="establishmentObj != undefined" class="establishment-form">
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                <mat-form-field *ngIf="establishmentObj.page != undefined" floatLabel="always"
                  class="establishment-form-field">
                  <mat-label class="establishment-form-label">ID do estabelecimento</mat-label>
                  <input matInput value="{{establishmentObj.page._id}}" class="establishment-form-field-line" readonly
                    name="page._id" [(ngModel)]="establishmentObj.page._id">
                </mat-form-field>
                <button mat-icon-button class="dark-blue" [cdkCopyToClipboard]="establishmentObj.page._id"
                  matTooltip="Copiar">
                  <mat-icon>file_copy</mat-icon>
                </button>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                <mat-form-field floatLabel="always" class="establishment-form-field">
                  <mat-label class="establishment-form-label">Token</mat-label>
                  <input matInput value="{{establishmentObj.page_token}}" class="establishment-form-field-line" readonly
                    name="page_token" [(ngModel)]="establishmentObj.page_token">
                </mat-form-field>
                <button mat-icon-button class="dark-blue" [cdkCopyToClipboard]="establishmentObj.page_token"
                  matTooltip="Copiar">
                  <mat-icon>file_copy</mat-icon>
                </button>
              </div>
              <mat-form-field floatLabel="always" class="establishment-form-field">
                <mat-label class="establishment-form-label" style="font-size: large;">ID Externo do Restaurante
                </mat-label>
                <input matInput value="{{establishmentObj.external_id}}" class="establishment-form-field-line"
                  name="external_id" [(ngModel)]="establishmentObj.external_id">
              </mat-form-field>
            </form>
          </div>
          <div class="webhooks-div" fxLayout="column" fxLayoutAlign="center center">
            <h1 class="webhooks-title">Webhooks:</h1>
            <div *ngIf="establishmentObj != undefined">
              <mat-slide-toggle *ngIf="establishmentObj.active" class="status-slider" value="establishmentObj.active"
                [(ngModel)]="establishmentObj.active">
                Status
              </mat-slide-toggle>
              <mat-slide-toggle *ngIf="!establishmentObj.active" class="status-slider" value="establishmentObj.active"
                [(ngModel)]="establishmentObj.active">
                Status
              </mat-slide-toggle>
            </div>
            <br>
            <form *ngIf="establishmentObj != undefined" style="margin-bottom:40px;" class="establishment-form">
              <mat-form-field class="establishment-form-field">
                <mat-label class="establishment-form-label">Pedidos Realizados</mat-label>
                <input matInput value="{{establishmentObj.order_accept.url}}" class="establishment-form-field-line"
                  name="order_accept.url" [(ngModel)]="establishmentObj.order_accept.url">
              </mat-form-field>
              <mat-radio-group name="access_method" [(ngModel)]="establishmentObj.order_accept.method"
                aria-label="Select an option">
                <mat-radio-button class="custom-radio-button-right" value="POST">POST</mat-radio-button>
                <mat-radio-button class="custom-radio-button-left" value="PUT">PUT</mat-radio-button>
              </mat-radio-group>
              <mat-form-field style="margin-top: 8px;" class="establishment-form-field">
                <mat-label class="establishment-form-label">Pedidos Cancelados</mat-label>
                <input matInput value="{{establishmentObj.order_cancel.url}}" class="establishment-form-field-line"
                  name="order_cancel.url" [(ngModel)]="establishmentObj.order_cancel.url">
              </mat-form-field>
              <mat-radio-group name="cancel_method" [(ngModel)]="establishmentObj.order_cancel.method"
                aria-label="Select an option">
                <mat-radio-button class="custom-radio-button-right" value="POST">POST</mat-radio-button>
                <mat-radio-button class="custom-radio-button-left" value="PUT">PUT</mat-radio-button>
              </mat-radio-group>
              <mat-form-field style="margin-top: 8px;" floatLabel="always" class="establishment-form-field">
                <mat-label class="establishment-form-label">Token Externo</mat-label>
                <input matInput value="{{establishmentObj.external_token}}" class="establishment-form-field-line"
                  name="external_token" [(ngModel)]="establishmentObj.external_token">
              </mat-form-field>
            </form>
          </div>
          <div class="buttons" fxLayout="row" fxLayoutAlign="start center">
            <button mat-button style="background-color: #003f63;" class="button"
              (click)="drawer.close()">Cancelar</button>
            <button mat-button style="background-color: #3399ff;" class="button"
              (click)="saveEstablishmentChanges()">Salvar</button>
          </div>
        </div>
      </div>
    </mat-drawer>
    <!-- /EDIT ESTABLISHMENT DRAWER -->
    <mat-drawer-content style="height: 100%; overflow-y: scroll;" infiniteScroll [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll()">
      <mat-toolbar>
        <mat-toolbar-row>
          <span class="title">Início</span>
          <span class="spacer"></span>
          <button (click)="logout()" mat-button class="signout-button">
            <mat-icon>exit_to_app</mat-icon>
            Sair
          </button>

        </mat-toolbar-row>
      </mat-toolbar>

      <mat-sidenav-container>
        <mat-sidenav mode="side" opened fixedInViewport="true">
          <div class="visible-sidenav">
            <img src="../assets/logo.svg" class="side-logo-img">
            <hr class="side-divider">
            <div class="side-title">
              <span class="int-system">{{partner?.name}}</span>
              <br>
              <span class="technical-manager">{{partner?.technical_manager}}</span>
            </div>
            <hr class="side-divider">

            <div class="side-subtitle">
              <span class="email">{{partner?.email}}</span>
              <br>
              <span class="phone">{{partner?.phone | slice:0:2}}
                {{partner?.phone | slice:2:7}}-{{partner?.phone | slice:7:11}}</span>
              <br>
              <!--<span class="int-count">Integrações: {{partnerService.integrationsCounter}}</span> -->
            </div>
            <button mat-flat-button class="edit-account-button" (click)="editPartner()">Editar Informações</button>
          </div>

        </mat-sidenav>
        <mat-sidenav-content>
          <div class="searchbar">
            <form class="searchbar-form">
              <input #search type="text" placeholder="&#xF002;" style="font-family:Arial, FontAwesome" name="search"
                [(ngModel)]="searchTermAux" (input)="cleaningSearch()">
            </form>
            <button class="searchbar-button" type="button" (click)="searchEstablishments()">Buscar</button>
            <mat-form-field class="status_filter">
              <mat-icon matPrefix *ngIf="filterStatus == null">panorama_fish_eye</mat-icon>
              <mat-icon matPrefix *ngIf="filterStatus === true" class="light-green">lens</mat-icon>
              <mat-icon matPrefix *ngIf="filterStatus === false" class="reddish-brown">lens</mat-icon>
              <mat-select>
                <mat-option (click)="filterEstablishmentsByStatus(null)">
                  <mat-icon matPrefix>panorama_fish_eye</mat-icon>
                </mat-option>
                <mat-option (click)="filterEstablishmentsByStatus(true)">
                  <mat-icon matPrefix class="light-green">lens
                  </mat-icon>
                  Ativo
                </mat-option>
                <mat-option (click)="filterEstablishmentsByStatus(false)">
                  <mat-icon matPrefix class="reddish-brown">lens
                  </mat-icon>
                  Inativo
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="panels" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
            <mat-list class="list">
              <mat-list-item>
                <span fxFlex="40%" class="title-header title-header__establishment">Estabelecimento</span>
                <span fxFlex="25%" class="title-header">E-mail</span>
                <span fxFlex="25%" class="title-header">CPF/CNPJ</span>
                <span fxFlex="5%" class="title-header title-header__status">Status</span>
              </mat-list-item>
              <mat-list-item *ngFor="let e of partnerEstablishmentService.establishments; let i = index" [ngClass] = "e.page.establishment?.sign.active ? '' : 'blocked-partner'">
                <div fxFlex="40%">
                  <img mat-list-avatar style="border:2px solid #81D977"
                    *ngIf="!e.page.image && e.page.establishment.sign.active && e.page.establishment.isGRFood"
                    src="../../../assets/fastmeal-icon.svg" (click)="openDrawer(e)">
                  <img mat-list-avatar style="border:2px solid #B3312E"
                    *ngIf="!e.page.image && !e.page.establishment.sign.active && e.page.establishment.isGRFood"
                    src="../../../assets/fastmeal-icon.svg" (click)="openDrawer(e)">
                  <img mat-list-avatar style="border:2px solid #81D977"
                    *ngIf="e.page.image && e.page.establishment.sign.active && e.page.establishment.isGRFood"
                    src="{{e.page.image}}" (click)="openDrawer(e)">
                  <img mat-list-avatar style="border:2px solid #B3312E"
                    *ngIf="e.page.image && !e.page.establishment.sign.active && e.page.establishment.isGRFood"
                    src="{{e.page.image}}" (click)="openDrawer(e)">
                  <img mat-list-avatar style="border:2px solid #3399ff"
                    *ngIf="!e.page.image && !e.page.establishment.isGRFood" src="../../../assets/fastmeal-icon.svg"
                    (click)="openDrawer(e)">
                  <img mat-list-avatar style="border:2px solid #3399ff"
                    *ngIf="e.page.image && !e.page.establishment.isGRFood" src="{{e.page.image}}" (click)="openDrawer(e)">
                  <div fxFlex fxLayout="column" class="info-establishment">
                    <div mat-line (click)="openDrawer(e)">{{e.page_name}}</div>
                    <div mat-line *ngIf="e.page.establishment.sign.active" (click)="openDrawer(e)"> Válido até:
                      {{e.page.establishment.sign.dateExpiration | date: 'dd/MM/yyyy'}}</div>
                    <div mat-line style="color:#B3312E"
                      *ngIf="!e.page.establishment.sign.active && e.page.establishment.isGRFood" (click)="openDrawer(e)">
                      Expirado
                    </div>
                  </div>
                </div>
                <div class="email-column" (click)="openDrawer(e)" fxFlex="25%">
                  {{e.page.establishment.sign.email_invoice ? e.page.establishment.sign.email_invoice : "- - -"}}
                  <div>{{e.page.establishment.sign.email_invoice ? e.page.establishment.sign.email_invoice : "- - -"}}</div>
                </div>
                <div class="cpf" (click)="openDrawer(e)" fxFlex="25%">
                  {{e.page.establishment.sign.cpf_cnpj ? e.page.establishment.sign.cpf_cnpj.value : "- - -"}}
                </div>
                <!-- --------------- -->
                <div *ngIf="partnerService.partner.config" fxFlex="5%">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openDrawer(e)">
                      <mat-icon>edit</mat-icon>
                      <span>Editar Integração</span>
                    </button>
                    <button mat-menu-item (click)="changePlan(e)" *ngIf="partnerService.partner.config.register_plan"
                      [disabled]="!e.page.establishment.isGRFood || hasPlan(e.page_plan)">
                      <mat-icon>playlist_add</mat-icon>
                      <span>Adicionar Plano</span>
                    </button>
                    <!-- <button mat-menu-item (click)="manageModules(e)"> -->
                    <button mat-menu-item (click)="manageModules(e)"
                      *ngIf="partnerService.partner.config.manage_modules" [disabled]="!e.page.establishment.isGRFood">
                      <mat-icon>extension</mat-icon>
                      <span>Gerenciar Módulos</span>
                    </button>
                    <button mat-menu-item (click)="manageConfigs(e)"
                      *ngIf="partnerService.partner.config.set_configuration_data">
                      <mat-icon>settings</mat-icon>
                      <span>Configurações</span>
                    </button>
                    <button mat-menu-item (click)="editEstablishmentInfo(e)">
                      <mat-icon>edit</mat-icon>
                      <span>Editar Info do Estabelecimento</span>
                    </button>
                  </mat-menu>
                </div>

                <!-- -----------------------  -->
                <button fxFlex="5%" *ngIf="e.active" mat-icon-button matTooltip="Webhooks ativos" (click)="openDrawer(e)">
                  <mat-icon class="light-green">lens</mat-icon>
                </button>
                <button fxFlex="5%" *ngIf="!e.active" mat-icon-button matTooltip="Webhooks inativos" (click)="openDrawer(e)">
                  <mat-icon class="reddish-brown">lens</mat-icon>
                </button>
                <mat-divider></mat-divider>
              </mat-list-item>
            </mat-list>
          </div>
          <div *ngIf="partnerService.partner.config">
            <speed-dial *ngIf="partnerService.partner.config.register_page" class="speed-dial-container"
              [ngClass]="speedDialPosition" (fabClick)="onSpeedDialClicked($event)" [buttons]="speedDialButtons"
              [reverse-column-direction]="true">
            </speed-dial>
            <button *ngIf="!partnerService.partner.config.register_page" class="fab-add-establishments" mat-fab
              (click)="openDrawer()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <div *ngIf="!partnerService.partner.config">
            <button class="fab-add-establishments" mat-fab (click)="openDrawer()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
<div *ngIf="innerWidth < 950" fxFill>
  <img src="../../../assets/cellphone-disabled.svg" style="width: 100%;">
</div>