import { Component, OnInit, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Page } from '../../shared/interfaces/page';
import { PageService } from 'src/app/shared/_services/page/page.service';

@Component({
  selector: "app-register-page",
  templateUrl: "./register-page.component.html",
  styleUrls: ["./register-page.component.scss"],
})

export class RegisterPageComponent implements OnInit {

  public innerWidth;

  constructor(private router: Router) {this.innerWidth = window.innerWidth;}

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {}

  backHome(){
    this.router.navigate(['']);
  }
}
