import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  public url = environment.url + "/partnerauth";

  constructor(private http: HttpClient) { }

  getPlans(){
    return this.http.get(this.url + "/plans");
  }

  // getPlanByPageId(id){
  //   let url = this.url + "/etc/"
  //   return this.http.get(url + id);
  // }

  sign(page_id, plan_id){
    let url = this.url + "/signature/";
    return this.http.post(url, {page_id, plan_id});
  }
}