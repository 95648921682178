import { Partner } from "./../../shared/interfaces/partner";
import { SignerService } from "./../../shared/_services/signer/signer.service";
import { AdminAuthService } from "../../shared/_services/admin-auth/admin-auth.service";
import { Component, OnInit, HostListener } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertService } from "src/app/shared/dialogs/alert/alert.service";
import { Router } from "@angular/router";
import { PartnerValidators } from "../../shared/validators/partner";

@Component({
  selector: "app-signer-main",
  templateUrl: "./signer-main.component.html",
  styleUrls: ["./signer-main.component.scss"],
})
export class SignerMainComponent implements OnInit {
  public partner: Partner = {};
  public innerWidth;

  constructor(
    private adminAuthService: AdminAuthService,
    private signerService: SignerService,
    public snackbar: MatSnackBar,
    public alertService: AlertService,
    private router: Router,
    public partnerVal: PartnerValidators
  ) {
    this.innerWidth = window.innerWidth;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {}

  registerPartner() {
    if (
      this.partnerVal.nameFormControl.invalid ||
      this.partnerVal.technicalManagerFormControl.invalid ||
      this.partnerVal.emailFormControl.invalid ||
      this.partnerVal.phoneFormControl.invalid ||
      this.partnerVal.passwordFormControl.invalid
    ) {
      this.snackbar.open("Complete os campos corretamente", "Fechar", {
        duration: 3000,
      });
    } else {
      this.signerService.registerPartner(this.partner).subscribe(
        (res: any) => {
          if (res.success) {
            this.snackbar.open(
              "Você agora está cadastrado no Portal de Integração. Faça login!",
              "Fechar",
              {
                duration: 5000,
              }
            );
            this.router.navigate(["login"]);
          } else {
            this.alertService.showAlert("Erro", res.message);
          }
        },
        (err: any) => console.log(err)
      );
    }
  }
}
