<div *ngIf="innerWidth>950" fxLayout="column" fxLayoutAlign="start center"
  fxLayoutGap="15px" class="main">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px"
    class="toolbar">
    <span class="title">Dados do Responsável</span>
    <div style="height: 70%; border: 1px solid white;"></div>
    <img src="../../../../assets/logo-robot-white.svg" style="height: 80%;">
  </div>

  <div class="form" *ngIf="page.address">
    <mat-form-field class="field">
      <mat-label class="label">Nome do Responsável</mat-label>
      <input matInput class="field-line" name="name" [(ngModel)]="page.name"
        [formControl]="pageVal.nameFormControl">
      <mat-error *ngIf="pageVal.nameFormControl.invalid">{{pageVal.getNameErrorMessage()}}</mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutGap="25px">
      <mat-form-field class="field">
        <mat-label class="label">CPF</mat-label>
        <input matInput class="field-line" name="cpf" [(ngModel)]="page.cpf" mask="000.000.000-00"
          [formControl]="pageVal.cpfFormControl">
        <mat-error *ngIf="pageVal.cpfFormControl.invalid">{{pageVal.getCpfErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field class="field">
        <mat-label class="label">Data de Nascimento</mat-label>
        <input matInput class="field-line" type="date" name="birthDate"
          [(ngModel)]="page.birthDate"
          [formControl]="pageVal.birthDateFormControl">
        <mat-error *ngIf="pageVal.birthDateFormControl.invalid">{{pageVal.getBirthDateErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
      <mat-form-field class="field">
        <mat-label class="label">CEP</mat-label>
        <input matInput class="field-line" name="address.postalCode" mask="00000-000"
          [(ngModel)]="page.address.postalCode"
          [formControl]="pageVal.postalCodeFormControl">
        <mat-error *ngIf="pageVal.postalCodeFormControl.invalid">{{pageVal.getPostalCodeErrorMessage()}}</mat-error>
      </mat-form-field>
      <button mat-icon-button style="color: #003f63" (click)="getAddress()"
        [disabled]="pageVal.postalCodeFormControl.invalid">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
      <mat-form-field class="field">
        <mat-label class="label">Endereço</mat-label>
        <input matInput class="field-line" name="street" [(ngModel)]="street"
          [formControl]="pageVal.streetFormControl">
        <mat-error *ngIf="pageVal.streetFormControl.invalid">{{pageVal.getStreetErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field class="field" style="width: 60px;">
        <mat-label class="label">Nº</mat-label>
        <input matInput class="field-line" name="number" [(ngModel)]="number"
          [formControl]="pageVal.numberFormControl">
        <mat-error *ngIf="pageVal.numberFormControl.invalid">{{pageVal.getNumberErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="25px">
      <mat-form-field class="field">
        <mat-label class="label">Complemento</mat-label>
        <input matInput class="field-line" name="address.complement"
          [(ngModel)]="page.address.complement">
      </mat-form-field>
      <mat-form-field class="field">
        <mat-label class="label">Bairro</mat-label>
        <input matInput class="field-line" name="address.district"
          [(ngModel)]="page.address.district"
          [formControl]="pageVal.districtFormControl">
        <mat-error *ngIf="pageVal.districtFormControl.invalid">{{pageVal.getDistrictErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    <mat-form-field class="field">
      <mat-label class="label">Cidade</mat-label>
      <input matInput class="field-line" name="address.city"
        [(ngModel)]="page.address.city" [formControl]="pageVal.cityFormControl">
      <mat-error *ngIf="pageVal.cityFormControl.invalid">{{pageVal.getCityErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="field">
      <mat-label class="label">Estado</mat-label>
      <input matInput class="field-line" name="address.state"
        [(ngModel)]="page.address.state"
        [formControl]="pageVal.stateFormControl">
      <mat-error *ngIf="pageVal.stateFormControl.invalid">{{pageVal.getStateErrorMessage()}}</mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px"
    style="padding-bottom: 25px;">
    <button mat-stroked-button style="color: #003f63" (click)="previousForm()">Voltar</button>
    <button mat-flat-button class="next-button" (click)="submit()"
      style="background-color: #003f63; color: white">Concluir</button>
  </div>

</div>

<!-- ---------------------------CELL PHONE --------------------------- -->

<div *ngIf="innerWidth<=950" fxLayout="column" fxLayoutAlign="start center"
  fxLayoutGap="15px" class="main">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px"
    class="toolbar">
    <span class="title">Dados do Estabelecimento</span>

  </div>

  <div class="form" *ngIf="page.address">
    <mat-form-field class="field">
      <mat-label class="label">Nome do Responsável</mat-label>
      <input matInput class="field-line" name="name" [(ngModel)]="page.name"
        [formControl]="pageVal.nameFormControl">
      <mat-error *ngIf="pageVal.nameFormControl.invalid">{{pageVal.getNameErrorMessage()}}</mat-error>
    </mat-form-field>

    <mat-form-field class="field">
      <mat-label class="label">CPF</mat-label>
      <input matInput class="field-line" name="cpf" [(ngModel)]="page.cpf" mask="000.000.000-00"
        [formControl]="pageVal.cpfFormControl">
      <mat-error *ngIf="pageVal.cpfFormControl.invalid">{{pageVal.getCpfErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="field">
      <mat-label class="label">Data de Nascimento</mat-label>
      <input matInput class="field-line" type="date" name="birthDate"
        [(ngModel)]="page.birthDate"
        [formControl]="pageVal.birthDateFormControl">
      <mat-error *ngIf="pageVal.birthDateFormControl.invalid">{{pageVal.getBirthDateErrorMessage()}}</mat-error>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
      <mat-form-field class="field">
        <mat-label class="label">CEP</mat-label>
        <input matInput class="field-line" name="address.postalCode" mask="00000-000"
          [(ngModel)]="page.address.postalCode"
          [formControl]="pageVal.postalCodeFormControl">
        <mat-error *ngIf="pageVal.postalCodeFormControl.invalid">{{pageVal.getPostalCodeErrorMessage()}}</mat-error>
      </mat-form-field>
      <button mat-icon-button style="color: #003f63" (click)="getAddress()"
        [disabled]="pageVal.postalCodeFormControl.invalid">
        <mat-icon>search</mat-icon>
      </button>
    </div>

    <mat-form-field class="field">
      <mat-label class="label">Endereço</mat-label>
      <input matInput class="field-line" name="street" [(ngModel)]="street"
        [formControl]="pageVal.streetFormControl">
      <mat-error *ngIf="pageVal.streetFormControl.invalid">{{pageVal.getStreetErrorMessage()}}</mat-error>
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="25px">
      <mat-form-field class="field" style="width: 60px;">
        <mat-label class="label">Nº</mat-label>
        <input matInput class="field-line" name="number" [(ngModel)]="number"
          [formControl]="pageVal.numberFormControl">
        <mat-error *ngIf="pageVal.numberFormControl.invalid">{{pageVal.getNumberErrorMessage()}}</mat-error>
      </mat-form-field>


      <mat-form-field class="field">
        <mat-label class="label">Complemento</mat-label>
        <input matInput class="field-line" name="address.complement"
          [(ngModel)]="page.address.complement">
      </mat-form-field>
    </div>

    <mat-form-field class="field">
      <mat-label class="label">Bairro</mat-label>
      <input matInput class="field-line" name="address.district"
        [(ngModel)]="page.address.district"
        [formControl]="pageVal.districtFormControl">
      <mat-error *ngIf="pageVal.districtFormControl.invalid">{{pageVal.getDistrictErrorMessage()}}</mat-error>
    </mat-form-field>

    <mat-form-field class="field">
      <mat-label class="label">Cidade</mat-label>
      <input matInput class="field-line" name="address.city"
        [(ngModel)]="page.address.city" [formControl]="pageVal.cityFormControl">
      <mat-error *ngIf="pageVal.cityFormControl.invalid">{{pageVal.getCityErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="field">
      <mat-label class="label">Estado</mat-label>
      <input matInput class="field-line" name="address.state"
        [(ngModel)]="page.address.state"
        [formControl]="pageVal.stateFormControl">
      <mat-error *ngIf="pageVal.stateFormControl.invalid">{{pageVal.getStateErrorMessage()}}</mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px"
    style="padding-bottom: 25px;">
    <button mat-stroked-button style="color: #003f63" (click)="previousForm()">Voltar</button>
    <button mat-flat-button class="next-button" (click)="submit()"
      style="background-color: #003f63; color: white">Concluir</button>
  </div>

</div>

<!-- ----------/CELL-PHONE------------ -->