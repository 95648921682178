<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px" class="main">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" class="toolbar">
    <span class="title">Dados do Estabelecimento</span>
    <div *ngIf="innerWidth> 950" style="height: 70%; border: 1px solid white;"></div>
    <img *ngIf="innerWidth> 950" src="../../../../assets/logo-robot-white.svg" style="height: 80%;">
  </div>
  <div fxLayoutAlign="end end">
    <div class="avatar-circle" fxLayout="column" fxLayoutAlign="center center">
      <img *ngIf="pageService.image" [src]="pageService.image" style="height: 100%;">
    </div>
    <button (click)="fileInput.click()" class="upload-button" mat-mini-fab>
      <mat-icon>insert_photo</mat-icon>
    </button>
    <input hidden type="file" (change)="fileChangeEvent($event)" #fileInput id="file">
  </div>


  <div fxLayout="column" fxLayoutAlign="center center" class="form-div">
    <form class="form">
      <mat-form-field class="field">
        <mat-label class="label">Nome do Estabelecimento</mat-label>
        <input matInput class="field-line" name="establishmentName" [(ngModel)]="page.estabilishmentName"
          [formControl]="pageVal.establishmentNameFormControl">
        <mat-error *ngIf="pageVal.establishmentNameFormControl.invalid">{{pageVal.getEstablishmentNameErrorMessage()}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="field">
        <mat-label class="label">CPF ou CNPJ</mat-label>
        <input matInput class="field-line" name="cpfcnpj" mask="000.000.000-00||00.000.000/0000-00"
          [(ngModel)]="page.cpfcnpj" [formControl]="pageVal.cpfcnpjFormControl">
        <mat-error *ngIf="pageVal.cpfcnpjFormControl.invalid">{{pageVal.getCpfcnpjErrorMessage()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="field">
        <mat-label class="label">DDD + Telefone</mat-label>
        <input matInput class="field-line" name="phone" mask="(00) 00000-0000" [(ngModel)]="page.phone"
          [formControl]="pageVal.phoneFormControl">
        <mat-error *ngIf="pageVal.phoneFormControl.invalid">{{pageVal.getPhoneErrorMessage()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="field">
        <mat-label class="label">Email</mat-label>
        <input matInput class="field-line" name="email" [(ngModel)]="page.email"
          [formControl]="pageVal.emailFormControl">
        <mat-error *ngIf="pageVal.emailFormControl.invalid">{{pageVal.getEmailErrorMessage()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="field">
        <mat-label class="label">Nome de Usuário</mat-label>
        <input matInput class="field-line" name="username" [(ngModel)]="page.username"
          [formControl]="pageVal.usernameFormControl">
        <mat-error *ngIf="pageVal.usernameFormControl.invalid">{{pageVal.getUsernameErrorMessage()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="field">
        <mat-label class="label">Senha</mat-label>
        <input matInput class="field-line" type="password" name="password" [(ngModel)]="page.password"
          [formControl]="pageVal.passwordFormControl">
        <mat-error *ngIf="pageVal.passwordFormControl.invalid">{{pageVal.getPasswordErrorMessage()}}</mat-error>
      </mat-form-field>
    </form>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px" style="padding-bottom: 25px;">
    <button mat-stroked-button class="next-button" (click)="backToHome()" style="color: #003f63">Cancelar</button>
    <button mat-flat-button style="background-color: #003f63; color: white" (click)="nextForm()">Próximo</button>
  </div>
</div>