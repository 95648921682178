<div *ngIf="innerWidth > 950">
  <div mat-dialog-title class="div-edit-partner-title">
    <div fxLayout="row" fxLayoutAlign="center center">
      <img style="width: 20%;" src="../../../assets/logo-robot.svg">
      <div class="divider"></div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px">
        <b><span class="edit-partner-title">Editar Dados</span></b>
        <!-- <b><span class="edit-partner-title">Dados</span></b> -->
      </div>
    </div>
  </div>
  <div mat-dialog-content class="div-edit-partner-content">
    <!-- <br>
    <mat-label style="margin-left: 5%; font-size: 20px; color: #003f63;">Logo</mat-label>
    <div style="margin-left: 5%; margin-top: 2%;">
      <input
        #myUploadButton
        class="my-upload-button"
        type="file"
        accept="image/png,image/jpeg"
        (change)="uploadButton($event)" 
        >
    </div>
    <br> -->
    <div class="form-div" fxLayout="col" fxLayoutAlign="center center">
      <form class="edit-partner-form">
        <mat-form-field class="edit-partner-field">
          <mat-label class="edit-partner-label">Sistema Integrador *</mat-label>
          <input matInput class="edit-partner-field-line" [(ngModel)]="editingPartner.name" name="name"
            [formControl]="partnerVal.nameFormControl">
          <mat-error *ngIf="partnerVal.nameFormControl.invalid">{{partnerVal.getNameErrorMessage()}}</mat-error>
        </mat-form-field>

        <mat-form-field class="edit-partner-field">
          <mat-label class="edit-partner-label">Nome da Empresa *</mat-label>
          <input matInput class="edit-partner-field-line" [(ngModel)]="editingPartner.company_name" name="establishment"
            [formControl]="partnerVal.establishmentNameFormControl">
          <mat-error *ngIf="partnerVal.establishmentNameFormControl.invalid">{{partnerVal.getNameErrorMessage()}}</mat-error>
        </mat-form-field>

        <mat-form-field class="edit-partner-field">
          <mat-label class="edit-partner-label">CNPJ da Empresa</mat-label>
          <input matInput class="edit-partner-field-line" 
          [(ngModel)]="editingPartner.cnpj" name="cnpj"mask="00.000.000/0000-00" [formControl]="partnerVal.cnpjFormControl">
          <mat-error *ngIf="partnerVal.cnpjFormControl.invalid">
            {{partnerVal.getCnpjErrorMessage()}}</mat-error>
        </mat-form-field>
        
        <mat-form-field class="edit-partner-field">
          <mat-label class="edit-partner-label">Responsável Técnico *</mat-label>
          <input matInput class="edit-partner-field-line" [(ngModel)]="editingPartner.technical_manager"
            name="technical_manager" [formControl]="partnerVal.technicalManagerFormControl">
          <mat-error *ngIf="partnerVal.technicalManagerFormControl.invalid">
            {{partnerVal.getTechnicalManagerErrorMessage()}}</mat-error>
        </mat-form-field>

        <mat-form-field class="edit-partner-field">
          <mat-label class="edit-partner-label">Email do Responsável Técnico *</mat-label>
          <input matInput class="edit-partner-field-line" [(ngModel)]="editingPartner.email" name="email">
        </mat-form-field>

        <mat-form-field class="edit-partner-field">
          <mat-label class="edit-partner-label">Contato do Responsável Técnico *</mat-label>
          <input matInput class="edit-partner-field-line" [(ngModel)]="editingPartner.phone" type="text" name="phone"
            mask="(00) 00000-0000" [formControl]="partnerVal.phoneFormControl">
          <mat-error *ngIf="partnerVal.phoneFormControl.invalid">
            {{partnerVal.getPhoneErrorMessage()}}</mat-error>
        </mat-form-field>

        <mat-form-field class="edit-partner-field">
          <mat-label class="edit-partner-label">Contato do Suporte *</mat-label>
          <input matInput class="edit-partner-field-line" [(ngModel)]="editingPartner.suport_contact" type="text" name="suport-contact"
          [formControl]="partnerVal.contactSupportFormControl">
          <mat-error *ngIf="partnerVal.contactSupportFormControl.invalid">
            {{partnerVal.getPhoneSupportErrorMessage()}}</mat-error>
        </mat-form-field>
      </form>
    </div>
    <div *ngIf="!editingPartner.has_contract_terms" class="edit-terms-use">
      <mat-checkbox [(ngModel)]="editingPartner.has_contract_terms"></mat-checkbox>
      <mat-label>Aceito os <a class="term-url" href="https://assets.anota.ai/Condicoes_Gerais_API.pdf">termos e diretrizes</a> como parceiro Anota Ai.</mat-label>
    </div>
    <br>
    <br>
    <div fxLayout="row" fxLayoutAlign="center center">
      <button [disabled]="!editingPartner.has_contract_terms" mat-flat-button flex="33" class="sign-in-button"
        style="background-color: #f3f3f6; border: 2px solid #3399ff; color: #3399ff;" (click)="cancel()">Cancelar</button>
      <button [disabled]="!editingPartner.has_contract_terms" mat-flat-button flex="33" class="sign-in-button" style="background-color: #3399ff"
        (click)="confirm()">Confirmar</button>
    </div>
    <br>
    <br>
  </div>
  <div mat-dialog-actions></div>
</div>

<!-- <div *ngIf="innerWidth <= 950" FxFil>

  <div mat-dialog-title class="div-edit-partner-title" fxFill>
    <div fxLayout="row" fxLayoutAlign="center center" fxFill>
      <img class="img" style="width: 40%;" src="../../../assets/logo-robot.svg">
      <div class="divider"></div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px">
        <b><span class="edit-partner-title">Editar</span></b>
        <b><span class="edit-partner-title">Dados</span></b>
      </div>

    </div>
  </div>
  <div mat-dialog-content class="div-edit-partner-content">
    <div class="form-div" fxLayout="col" fxLayoutAlign="center center">
      <form class="edit-partner-form">
        <mat-form-field class="edit-partner-field">
          <mat-label class="edit-partner-label">Sistema Integrador</mat-label>
          <input matInput class="edit-partner-field-line" [(ngModel)]="editingPartner.name" name="name">
        </mat-form-field>

        <mat-form-field class="edit-partner-field">
          <mat-label class="edit-partner-label">Responsável Técnico</mat-label>
          <input matInput class="edit-partner-field-line" [(ngModel)]="editingPartner.technical_manager"
            name="technical_manager">
        </mat-form-field>

        <mat-form-field class="edit-partner-field">
          <mat-label class="edit-partner-label">Email</mat-label>
          <input matInput class="edit-partner-field-line" readonly [(ngModel)]="editingPartner.email" name="email">
        </mat-form-field>

        <mat-form-field class="edit-partner-field">
          <mat-label class="edit-partner-label">Contato do Responsável</mat-label>
          <input matInput class="edit-partner-field-line" [(ngModel)]="editingPartner.phone" name="phone">
        </mat-form-field>

        <mat-form-field class="edit-partner-field">
          <mat-label class="edit-partner-label">Contato do Suporte</mat-label>
          <input matInput class="edit-partner-field-line" [(ngModel)]="editingPartner.suport_contact" name="phone">
        </mat-form-field>
      </form>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-flat-button flex="33" class="sign-in-button"
        style="background-color: #003f63; border: 2px solid #3399ff" (click)="cancel()">Cancelar</button>
      <button mat-flat-button flex="33" class="sign-in-button" style="background-color: #3399ff"
        (click)="confirm()">Confirmar</button>
    </div>
    <br>
    <br>
  </div>
  <div mat-dialog-actions></div>
</div> -->
