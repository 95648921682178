<div mat-dialog-title class="div-alert-title">
  <div fxLayout="row" fxLayoutAlign="center center">
    <img class="img" src="../../../../assets/logo-robot.svg">
    <div class="divider"></div>
    <b class="alert-title">Configurações<span></span></b>
  </div>
</div>
<div mat-dialog-content class="div-alert-content">
  <mat-list>
    <!-- <mat-list-item>
      <mat-slide-toggle>Buscar por CEP</mat-slide-toggle>
    </mat-list-item>
    <mat-list-item>
      <mat-slide-toggle>Preencher automaticamente com CEP da unidade</mat-slide-toggle>
    </mat-list-item> -->
    <mat-list-item>
      <mat-slide-toggle style="color: white;" [(ngModel)]="configs.address_manual">Endereço manual</mat-slide-toggle>
    </mat-list-item>
    <!-- <mat-list-item>
      <mat-slide-toggle>Agrupar combos e promoções</mat-slide-toggle>
    </mat-list-item> -->
    <mat-list-item>
      <mat-slide-toggle style="color: white;" [(ngModel)]="configs.reference_point_required">Ponto de referência
        obrigatório</mat-slide-toggle>
    </mat-list-item>
  </mat-list>
  <br style="height: 10px; display: block; content:''">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <button mat-flat-button flex="33" class="button" style="background-color:white; color:#14243b"
      (click)="close()">Fechar</button>
    <button mat-flat-button flex="33" class="button" (click)="changeConfigs()">Salvar</button>
  </div>

  <br>
</div>
<div mat-dialog-actions></div>
