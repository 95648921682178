import { Router, NavigationExtras } from "@angular/router";
import { Component, OnInit, HostListener } from "@angular/core";
import { Page } from "src/app/shared/interfaces/page";
import { PageService } from "src/app/shared/_services/page/page.service";
import { PageValidators } from "src/app/shared/validators/page";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { ImgCropperService } from 'src/app/shared/dialogs/img-cropper/img-cropper.service';
import { AlertService } from 'src/app/shared/dialogs/alert/alert.service';

@Component({
  selector: "app-register-page-establishment",
  templateUrl: "./register-page-establishment.component.html",
  styleUrls: ["./register-page-establishment.component.scss"],
})
export class RegisterPageEstablishmentComponent implements OnInit {
  public page: Page = {};
  public innerWidth;
  public image;

  constructor(
    private router: Router,
    public pageService: PageService,
    public pageVal: PageValidators,
    private snackbar: MatSnackBar,
    public imgCropperService: ImgCropperService,
    private alertService: AlertService
  ) {
    this.innerWidth = window.innerWidth;
    if (this.pageService.page) {
      this.page = this.pageService.page;
    }
    this.image = this.pageService.image;
  }

  ngOnInit(): void {}

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  nextForm() {
    if (
      this.pageVal.establishmentNameFormControl.invalid ||
      this.pageVal.cpfcnpjFormControl.invalid ||
      this.pageVal.phoneFormControl.invalid ||
      this.pageVal.emailFormControl.invalid ||
      this.pageVal.usernameFormControl.invalid ||
      this.pageVal.passwordFormControl.invalid
    ) {
      this.snackbar.open("Complete os campos corretamente", "Fechar", {
        duration: 3000,
      });
    } else {
      this.pageService.page = this.page;
      this.router.navigate(["/register-page/owner"]);
    }
  }

  backToHome() {
    this.router.navigate([""]);
  }

  fileChangeEvent(event: any): void {
    console.log(event);
    this.imgCropperService.showCropper(event);
  }
}