import { PartnerAuthService } from '../../_services/partner-auth/partner-auth.service';
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class PartnerGuard implements CanActivate {
  constructor(public auth: PartnerAuthService, private router: Router) {}

  canActivate(): Promise<boolean> {
    this.auth.checkToken().then(
      (res) => {
        if(!res){
          this.router.navigate(['login']);
        }
      }
    )
    return this.auth.checkToken();
  }
}