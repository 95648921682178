import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { AlertService } from "../alert/alert.service";
import { PageService } from "../../_services/page/page.service";
import { EstablishmentImageService } from "../../_services/establishment-image/establishment-image.service";

@Component({
  selector: "app-img-cropper",
  templateUrl: "./img-cropper.component.html",
  styleUrls: ["./img-cropper.component.scss"],
})
export class ImgCropperComponent implements OnInit {
  public spinner = true;
  public imgFile;
  public croppedImage;
  public imgSize;

  constructor(
    private dialog: MatDialogRef<ImgCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private pageService: PageService,
    private establishmentImgService: EstablishmentImageService
  ) {
    this.imgFile = this.data.imgFile;
  }

  ngOnInit(): void {}

  imageCropped(event: ImageCroppedEvent) {
    console.log(event);
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    console.log("loaded");
  }

  cropperReady() {
    console.log("read");
    this.spinner = false;
  }

  loadImageFailed() {
    this.alertService.showAlert("Erro", "Falha ao carregar arquivo");
    this.dialog.close();
  }

  submit() {
    fetch(this.croppedImage)
      .then((res) => res.blob())
      .then((blob) => {
        this.pageService.image = this.croppedImage;
        this.pageService.imageFile = new File([blob], "File_name", {
          type: "image/png",
        });
        this.establishmentImgService.image = this.croppedImage;
        this.establishmentImgService.imageFile = new File([blob], "File_name", {
          type: "image/png",
        });
        this.imgSize = this.imgFile.size
        if (this.establishmentImgService.isToEdit) {
          this.establishmentImgService.uploadImage().subscribe(
            (res) => {
              window.location.reload();
            },
            (err) => {
              this.alertService.showAlert("Erro", "Esta imagem é muito grande")
            }
          );
        }
      });
    this.dialog.close();
  }

  cancel() {
    this.dialog.close();
  }
}
