import { RegisterPageComponent } from "./partner/register-page/register-page.component";
import { NotFoundComponent } from "./shared/components/not-found/not-found.component";
import { NotAuthorizedComponent } from "./signer/not-authorized/not-authorized.component";
import { LoginPartnerGuard } from "./shared/_guards/login-partner/login-partner.guard";
import { AdminGuard } from "./shared/_guards/admin/admin.guard";
import { SignerMainComponent } from "./signer/signer-main/signer-main.component";
import { LoginComponent } from "./partner/login/login.component";
import { MainComponent } from "./partner/main/main.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PartnerGuard } from "./shared/_guards/partner/partner.guard";
import { RegisterPageEstablishmentComponent } from "./partner/register-page/register-page-establishment/register-page-establishment.component";
import { RegisterPageOwnerComponent } from "./partner/register-page/register-page-owner/register-page-owner.component";
import { PlanCardComponent } from "./shared/components/plan-card/plan-card.component";
import { PlansComponent } from "./partner/plans/plans.component";
import { ModulesComponent } from "./partner/modules/modules.component";

const routes: Routes = [
  { path: "", component: MainComponent, canActivate: [PartnerGuard] },
  {
    path: "register-page",
    component: RegisterPageComponent,
    canActivate: [PartnerGuard],
    children: [
      {
        path: "establishment",
        component: RegisterPageEstablishmentComponent,
      },
      {
        path: "owner",
        component: RegisterPageOwnerComponent,
      },
      {
        path: "",
        redirectTo: "/register-page/establishment",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [LoginPartnerGuard],
  },
  { path: "not-authorized", component: NotAuthorizedComponent },
  { path: "signer", component: SignerMainComponent },
  { path: "plans/:id", component: PlansComponent },
  { path: "modules/:id", component: ModulesComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
