import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {

  constructor(private dialog: MatDialogRef<DeleteConfirmationComponent>) { }

  ngOnInit(): void {
  }

  cancel(){
    this.dialog.close()
  }

}
