import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ObjectID } from 'mongodb';
import { EstablishmentConfigs } from "../../interfaces/establishment-configs";
import { EstablishmentConfigsService } from "../../_services/establishment-configs/establishment-configs.service";
import { AlertService } from "../alert/alert.service";

@Component({
  selector: "app-establishment-configs",
  templateUrl: "./establishment-configs.component.html",
  styleUrls: ["./establishment-configs.component.scss"],
})
export class EstablishmentConfigsComponent implements OnInit {
  public configs: EstablishmentConfigs = {};
  public pageId: ObjectID;

  constructor(
    private dialog: MatDialogRef<EstablishmentConfigsComponent>,
    private configService: EstablishmentConfigsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService
  ) {
    this.pageId = this.data.pageId;
    this.getConfigs();
  }

  ngOnInit(): void {}

  close() {
    this.dialog.close();
  }

  getConfigs() {
    this.configService.getConfigs(this.pageId).subscribe(
      (res: any) => {
        console.log(res);
        this.configs.address_manual = res.address_manual;
        this.configs.reference_point_required = res.reference_point_required;
      },
      (err) => {
        this.dialog.close();
        this.alertService.showAlert(
          "Erro",
          "Ocorreu um erro ao buscar pelas configurações deste estabelecimento"
        );
      }
    );
  }

  changeConfigs() {
    console.log(this.configs);
    this.configService
      .changeConfigs(this.pageId, this.configs)
      .subscribe((res: any) => {
        console.log(res);
        this.dialog.close();
      });
  }
}
