import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AdminAuthService {
  adminAuthenticationState = new BehaviorSubject(false);

  private ipWhitelist = [
    "2804:14d:5e83:1aac::1002",
    "2804:14d:5e83:1aac::1001",
  ];

  private localIp: string;

  private adminAuthenticate: boolean = false;

  constructor(public http: HttpClient) {}

  checkIp(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this.adminAuthenticate) {
        this.http.get("https://jsonip.com/").subscribe((res: any) => {
          this.localIp = res.ip;
          this.adminAuthenticate = this.ipWhitelist.indexOf(this.localIp) > -1;
          resolve(this.adminAuthenticate);
        });
      } else {
        resolve(this.adminAuthenticate);
      }
    });
  }
}
