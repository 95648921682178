import { catchError } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class SignerService {

  public url = environment.url;

  constructor(private http: HttpClient) { }

  registerPartner(partner){
    let url = this.url + "/noauth/partner/create"
    return this.http.post(url, partner);
  }
}
