import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EstablishmentConfigsService {
  private url = environment.url;

  constructor(private http: HttpClient) {}

  getConfigs(pageId) {
    console.log(pageId);
    let url =
      this.url +
      "/partnerauth/integration-admin/listconfigurationdata/" +
      pageId;
    return this.http.get(url);
  }

  changeConfigs(pageId, configs) {
    console.log(pageId);
    let url =
      this.url + "/partnerauth/integration-admin/updatewebview/" + pageId;
    return this.http.post(url, configs);
  }
}
