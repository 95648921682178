import { ActivatedRoute } from '@angular/router';
import { environment } from "./../../../../environments/environment";
import { Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { tap, catchError } from "rxjs/operators";
import { AlertService } from "../../dialogs/alert/alert.service";

const TOKEN_KEY = "access_token";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded",
  }),
};

@Injectable({
  providedIn: "root",
})
export class PartnerAuthService implements OnInit {
  public url = environment.url + "/noauth/partner/login";
  public partnerAuthenticationState: boolean = false;
  private partnerId;
  private _token;
  public urlIntegration: string;

  constructor(
    private http: HttpClient,
    private helper: JwtHelperService,
    public alertService: AlertService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {}

  getJwtToken(): any {
    if (this._token == undefined || this._token == "") {
      this._token = localStorage.getItem(TOKEN_KEY);
    }
    return this._token;
  }

  checkToken(): Promise<boolean> {
    return new Promise((resolve) => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.urlIntegration = urlParams.get('access_token');
      if(this.urlIntegration) {
        localStorage.setItem('access_token', this.urlIntegration)
      }
      var token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        let decoded = this.helper.decodeToken(token);
        let isExpired = this.helper.isTokenExpired(token);
        if (!isExpired) {
          this.partnerAuthenticationState = true;
          this.partnerId = decoded;
          resolve(this.partnerAuthenticationState);
        } else {
          this.partnerAuthenticationState = false;
          localStorage.removeItem(TOKEN_KEY);
          resolve(this.partnerAuthenticationState);
        }
      } else {
        this.partnerAuthenticationState = false;
        resolve(this.partnerAuthenticationState);
      }
    });
  }

  login(credentials) {
    return this.http.post(this.url, credentials).pipe(
      tap((res: any) => {
        if (res.success) {
          this._token = res["access_token"];
          localStorage.setItem(TOKEN_KEY, res["access_token"]);
          this.partnerId = this.helper.decodeToken(res["access_token"]);
          this.partnerAuthenticationState = true;
        } else {
          this.alertService.showAlert("Erro", res.message);
        }
      }),
      catchError((err) => {
        throw new Error();
      })
    );
  }

  logout() {
    localStorage.removeItem(TOKEN_KEY);
    this.partnerAuthenticationState = false;
  }

  isAuthenticated(): Promise<boolean> {
    return this.checkToken();
  }
}
