<div *ngIf="innerWidth>950" class="main">
    <button class="fab-close" mat-mini-fab (click)="backHome()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <div fxLayout="column" fxLayoutAlign="center center" 
        fxLayoutGap="60px" style="padding-top: 50px; padding-bottom: 30px;">
        <div fxLayout="column" fxLayoutAlign="center center">
            <span style="color: white; font-family: typo-round; font-size:
                300%;">
                Não perca mais pedidos!</span>
            <br>
            <span style="color: white; font-family: typo-round; font-size:
                400%;">
                Contrate seu plano no
                <span style="padding-left: 8px; background-color: white; color:
                    #3399ff;">Anota
                </span>
                <span style="padding-right: 8px; background-color: white; color:
                    #003f63;">AI</span>
            </span>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="45px">
            <plan-card *ngFor="let p of plans" [title]="p.title"
                [description]="p.description" [price]="p.price"
                [period]="p.period"
                [mat_icon]="p.mat_icon" [period]="p.period"
                (click)="selectPlan(p)">
            </plan-card>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" class="bottom-bar">
            <button *ngIf="selected" mat-stroked-button class="next-button"
                (click)="confirmPlan()">Assinar Plano {{selected.title}}</button>
            <button *ngIf="!selected" mat-stroked-button class="next-button"
                style="background-color: gray; color: white" disabled>Plano Não
                Selecionado</button>
        </div>
    </div>
</div>

<!-- ----------------------- CELL PHONE ------------------------- -->
<div *ngIf="innerWidth<=950" class="main">
    <div fxLayout="column" fxLayoutAlign="center center" 
        fxLayoutGap="20px" style="padding-bottom: 70px;" >
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px"
            style="padding-top: 20px; ">
            <button class="fab-phone-close" mat-mini-fab (click)="backHome()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <span style="color: white; font-family: typo-round; font-size:
                180%;">
                Contrate seu plano
            </span>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="45px">
            <plan-card *ngFor="let p of plans" [title]="p.title"
                [description]="p.description" [price]="p.price"
                [period]="p.period"
                [mat_icon]="p.mat_icon" [period]="p.period"
                (click)="selectPlan(p)">
            </plan-card>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" class="bottom-bar-phone">
            <button *ngIf="selected" mat-stroked-button class="phone-next-button"
                (click)="confirmPlan()">Assinar Plano {{selected.title}}</button>
            <button *ngIf="!selected" mat-stroked-button class="phone-next-button"
                style="background-color: gray; color: white" disabled>Plano Não
                Selecionado</button>
        </div>
    </div>
</div>