import {
  SpeedDialPosition,
  SpeedDialComponent,
} from "./../../shared/components/speed-dial/speed-dial.component";
import { DeleteConfirmationComponent } from "./../../shared/dialogs/delete-confirmation/delete-confirmation.component";
import { PartnerService } from "./../../shared/_services/partner/partner.service";
import { Router } from "@angular/router";
import { PartnerAuthService } from "./../../shared/_services/partner-auth/partner-auth.service";
// import { DrawerCtrlService } from "../shared/services/drawer-ctrl/drawer-ctrl.service";
import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { PartnerEstablishment } from "src/app/shared/interfaces/partner-establishment";
import { MatSidenav } from "@angular/material/sidenav";
import { MatDialog } from "@angular/material/dialog";
import { EditPartnerComponent } from "src/app/shared/dialogs/edit-partner/edit-partner.component";
import { Partner } from "src/app/shared/interfaces/partner";
import { AlertService } from "src/app/shared/dialogs/alert/alert.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ImgCropperService } from "src/app/shared/dialogs/img-cropper/img-cropper.service";
import { EstablishmentImageService } from "src/app/shared/_services/establishment-image/establishment-image.service";
import { PartnerEstablishmentService } from "src/app/shared/_services/partner-establishment/partner-establishment.service";
import { EstablishmentConfigsComponent } from "src/app/shared/dialogs/establishment-configs/establishment-configs.component";
import { ModulesService } from "src/app/shared/_services/modules/modules.service";
import { PageInfoComponent } from 'src/app/shared/dialogs/page-info/page-info.component';

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  public establishmentObj: PartnerEstablishment = {};
  public accessKeyChecked: boolean = false;
  public partner: any;
  public searchTerm: string;
  public searchTermAux: string;
  public filterStatus = null;
  public innerWidth;
  public speedDialPosition = SpeedDialPosition.Bottom;

  public speedDialButtons = [
    {
      icon: "storefront",
      tooltip: "Integrar um estabelecimento",
    },
    {
      icon: "web",
      tooltip: "Adicionar uma página",
    },
  ];

  @ViewChild("drawer") public drawer: MatSidenav;
  @ViewChild("phoneDrawer") public phoneDrawer: MatSidenav;

  public page: number = 1;

  constructor(
    public dialog: MatDialog,
    public partnerService: PartnerService,
    public partnerEstablishmentService: PartnerEstablishmentService,
    private authService: PartnerAuthService,
    private router: Router,
    public alertService: AlertService,
    public snackbar: MatSnackBar,
    public imgCropperService: ImgCropperService,
    private establishmentImgService: EstablishmentImageService,
    private modulesService: ModulesService
  ) {
    this.innerWidth = window.innerWidth;
    this.partnerEstablishmentService.establishments = [];
    this.partnerEstablishmentService.getEstablishments("", this.page);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.partnerService.getPartner().subscribe(
      (res)=>{
        this.partner = res;
        this.partnerService.partner = this.partner;
        if(this.partner.has_contract_terms == undefined || this.partner.has_contract_terms == false){
          this.editPartner();
        }
      }
    );

  }

  onScroll() {
    console.log("scrolled");
    this.page++;
    this.partnerEstablishmentService.getEstablishments(
      this.searchTerm,
      this.page,
      this.filterStatus
    );
  }

  searchEstablishments() {
    this.searchTerm = this.searchTermAux;
    this.partnerEstablishmentService.establishments = [];
    this.page = 1;
    this.partnerEstablishmentService.getEstablishments(this.searchTerm);
  }

  cleaningSearch() {
    if (this.searchTermAux == "") {
      this.searchTerm = this.searchTermAux;
      this.partnerEstablishmentService.establishments = [];
      this.page = 1;
      this.partnerEstablishmentService.getEstablishments("", this.page);
    }
  }

  filterEstablishmentsByStatus(active) {
    this.filterStatus = active;
    this.partnerEstablishmentService.establishments = [];
    this.page = 1;
    this.partnerEstablishmentService.getEstablishments(
      this.searchTerm,
      this.page,
      active
    );
  }

  onSpeedDialClicked(btn: { icon: string }) {
    if (btn.icon == "storefront") {
      this.openDrawer();
    }
    if (btn.icon == "web") {
      this.router.navigate(["register-page/establishment"]);
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(["login"]);
    this.snackbar.open("Logout realizado com sucesso!", "Fechar", {
      duration: 3000,
    });
  }

  openDrawer(establishmentObj = undefined) {
    this.establishmentObj = establishmentObj;
    if (this.establishmentObj == undefined) {
      this.establishmentObj = {};
      this.establishmentObj.page_name = "Nome do Estabelecimento";
      this.establishmentObj.order_accept = {};
      this.establishmentObj.order_cancel = {};
      this.accessKeyChecked = false;
    } else {
      this.accessKeyChecked = true;
    }
    this.drawer.open();
  }

  fileChangeEvent(event: any): void {
    console.log(event);
    this.establishmentImgService.isToEdit = true;
    this.establishmentImgService.pageId = this.establishmentObj.page._id;
    this.imgCropperService.showCropper(event);

  }
  accessKeyCheck() {
    this.establishmentObj = {
      page_token: this.establishmentObj.page_token,
      order_accept: {
        method: "POST",
        url: "",
      },
      order_cancel: {
        method: "POST",
        url: "",
      },
      external_id: "",
      external_token: "",
      active: true,
    };
    this.partnerEstablishmentService
      .saveEstablishment(this.establishmentObj)
      .subscribe((res: any) => {
        console.log(res);
        if (res.success) {
          this.accessKeyChecked = true;
          this.establishmentObj = res.info;
          this.partnerEstablishmentService.establishments = [];
          this.partnerEstablishmentService.getEstablishments("", this.page);
        } else {
          console.log(res);
          this.alertService.showAlert("Erro", "Token inválido");
        }
      });
  }

  saveEstablishmentChanges() {
    console.log(this.establishmentObj);
    this.partnerEstablishmentService
      .saveEstablishment(this.establishmentObj)
      .subscribe((response) => {
        console.log(response);
        this.partnerEstablishmentService.establishments = [];
        this.partnerEstablishmentService.getEstablishments("", this.page);
        if (this.drawer) {
          this.drawer.close();
        } else if (this.phoneDrawer) {
          this.phoneDrawer.close();
        }
      });
  }

  hasPlan(pagePlan) {
    return pagePlan != undefined && pagePlan.end;
  }

  editPartner() {
    this.dialog.open(EditPartnerComponent);
    this.dialog.afterAllClosed.subscribe(()=>{
      this.partnerService.getPartner().subscribe(
        (res)=>{
          this.partner = res;
          this.partnerService.partner = this.partner;
        }
      );
    })
  }

  changePlan(e) {
    this.router.navigate(["plans", e.page._id]);
  }

  manageModules(e) {
    this.router.navigate(["modules", e.page._id]);
    this.modulesService.pageName = e.page_name;
  }

  manageConfigs(establishment) {
    this.dialog.open(EstablishmentConfigsComponent, {
      data: {
        pageId: establishment.page._id,
      },
    });
  }

  editEstablishmentInfo(establishment) {
    let dialogRef = this.dialog.open(PageInfoComponent, {
      data: { page: establishment },
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          establishment.page_name = result.name;
          establishment.page_name_id = result.nameId;
        }
    });
  }
}
