import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { PartnerService } from "../partner/partner.service";
import { PartnerEstablishment } from "../../interfaces/partner-establishment";

@Injectable({
  providedIn: "root",
})
export class PartnerEstablishmentService {
  private url = environment.url + "/partnerauth/partner";
  public establishments = [];

  constructor(
    private http: HttpClient,
    private partnerService: PartnerService
  ) {}

  getEstablishments(searchTerm, page = 1, active = "") {
    var url = this.url + "/listpages/v2?limit=15&page=" + page;
    if (active != null) {
      active = active.toString();
      if (active != "" && active != undefined) {
        url = url + "&active=" + active;
      }
    }
    if (searchTerm != "" && searchTerm != undefined) {
      url = url + "&name=" + searchTerm;
    }
    this.http.get(url).subscribe((res: any) => {
      this.establishments = this.establishments.concat(res.info.docs);
      this.partnerService.integrationsCounter = res.info.totalDocs;
    });
  }

  saveEstablishment(document) {
    const url = this.url + "/linkpage";
    return this.http.post(url, document);
  }
}
