import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, Inject, HostListener } from "@angular/core";
import { Partner } from "../../interfaces/partner";
import { PartnerService } from "../../_services/partner/partner.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PartnerValidators } from "../../validators/partner";
import { AlertService } from "../alert/alert.service";

@Component({
  selector: "app-edit-partner",
  templateUrl: "./edit-partner.component.html",
  styleUrls: ["./edit-partner.component.scss"],
})
export class EditPartnerComponent implements OnInit {
  public editingPartner: Partner;
  public innerWidth;

  constructor(
    private dialog: MatDialogRef<EditPartnerComponent>,
    public partnerService: PartnerService,
    public snackbar: MatSnackBar,
    public partnerVal: PartnerValidators,
    public alertService: AlertService
  )
  {
    this.innerWidth = window.innerWidth;
    this.editingPartner = JSON.parse(JSON.stringify(this.partnerService.partner)) ;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.dialog.disableClose = true;
    if(this.editingPartner.has_contract_terms){
    this.dialog.disableClose = false;
    }
  }

  uploadButton(event){
    if(event.target.files && event.target.files[0]){
        const base64 = event.target.files[0];
        let reader = new FileReader;
        reader.readAsDataURL(base64 as Blob)
        reader.onloadend = ()=>{
          this.editingPartner.logo = reader.result as string
        }
    }
  }

  clearFile(){
    this.editingPartner.logo = [];
  }

  cancel() {
    if (this.dialog.disableClose) {
      this.alertService.showAlert("Erro", "Complete os campos corretamente");
    } else {
      this.dialog.close();
    }
  }

  confirm() {
    if (
      this.partnerVal.phoneFormControl.invalid ||
      this.partnerVal.contactSupportFormControl.invalid ||
      this.partnerVal.nameFormControl.invalid ||
      this.partnerVal.technicalManagerFormControl.invalid
    ) {
      this.alertService.showAlert("Erro", "Complete os campos corretamente");
    } else {
      this.dialog.close();
      this.partnerService
        .editPartner(this.editingPartner)
        .subscribe((res: any) => {
          if (res.success) {
            this.snackbar.open("Usuário alterado com sucesso!", "Fechar", {
              duration: 3000,
            });
            this.partnerService.getPartner().subscribe((res:any)=>{
              this.partnerService.partner = res;
            });
          } else {
            this.snackbar.open("Ocorreu um erro!", "Fechar", {
              duration: 3000,
            });
          }
        }, (err)=>{
          this.snackbar.open("Ocorreu um erro!", "Fechar", {
            duration: 3000,
          });
        });
    }
  }
}
