<div fxLayout="row" fxFill *ngIf="innerWidth > 950">
  <div fxLayout="column" fxLayoutAlign="center center" class="image-div">
    <button class="fab-close" mat-mini-fab (click)="backHome()">
      <mat-icon>clear</mat-icon>
    </button>
    <img src="../../../assets/white-logo.svg" width="80%">
  </div>
  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
</div>
<div fxLayout="row" fxFill *ngIf="innerWidth <= 950">
  <div class="main-content-phone">
    <router-outlet></router-outlet>
  </div>
</div>
