import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ModulesService {
  public url = environment.url;
  public modules = [];
  public pageModules;
  public pageName;

  constructor(private http: HttpClient) {
    this.pageModules = {};
    this.pageModules.data = [];
  }

  getModules(id) {
    let url = this.url + "/noauth/modules";
    this.http.get(url).subscribe((res: any) => {
      console.log(res);
      this.modules = res.info;
      for (let i = 0; i < this.modules.length; i++) {
        this.modules[i].isActive = false;
        this.modules[i].isTest = false;
      }
      this.getModulesByPage(id);
    });
  }

  getModulesByPage(id) {
    let url = this.url + "/partnerauth/pagemodules/" + id;
    this.http.get(url).subscribe((res: any) => {
      console.log(res);
      this.pageName = res.page.name;
      this.pageModules = res.data;
      for (let i = 0; i < this.pageModules.length; i++) {
        for (let j = 0; j < this.modules.length; j++) {
          if (this.pageModules[i].module == this.modules[j]._id) {
            if (this.pageModules[i].status) {
              if (this.pageModules[i].should_not_charge) {
                this.modules[j].isTest = true;
              }
              this.modules[j].isActive = true;
            }
          }
        }
      }
    });
  }

  signModule(pageModule) {
    let url = this.url + "/partnerauth/integration-admin/signmodule";
    return this.http.post(url, pageModule);
  }

  cancelModule(id_module, page, partnerId, isTest) {
    let url = this.url + "/partnerauth/integration-admin/cancelmodule";
    console.log({
      id_module: id_module,
      page: page,
      partnerId: partnerId,
      isTest: isTest,
    });
    return this.http.post(url, {
      id_module: id_module,
      page: page,
      partnerId: partnerId,
      isTest: isTest,
    });
  }
}
