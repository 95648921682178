import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentImageService {

  public image = "../../../../assets/fastmeal-icon.svg";
  public imageFile;
  public isToEdit;
  public pageId;

  constructor(private http: HttpClient) { }

  uploadImage() {
    // debugger
    let url = environment.url + "/partnerauth/page/uploadImg/";
    let imgFile = this.imageFile;
    const formData = new FormData();
    formData.append('image', imgFile);
    formData.append('pageId', this.pageId);
    console.log(formData)
    return this.http.post(url, formData);
  }
}
