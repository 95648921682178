<div mat-dialog-content class="div-alert-content">
    <div class="content-header" fxLayoutAlign="end start">
        <div mat-dialog-actions >
            <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <img 
            *ngIf="page.image"
            class="img"
            src="{{page.image}}">
        <img
            *ngIf="!page.image"
            class="img"
            src="../../../assets/fastmeal-icon.svg">
    </div>
    <form [formGroup]="pageInfoForm" (submit)="save(submitButton)">
        <mat-form-field floatLabel="always" class="page-form-field">
            <mat-label class="page-form-label">Nome da Página</mat-label>
            <input
                formControlName="name"
                matInput 
                class="page-form-field-line">
        </mat-form-field>
        <mat-form-field floatLabel="always" class="page-form-field" (keyup)="copyValue($event);">
            <mat-label class="page-form-label">NameID
                <span class="page-form-field-ex">
                    https://app.anota.ai/p/
                    <span class="page-form-field-nameId">{{values}}</span>
                </span>
            </mat-label>
            <input
                
                formControlName="nameId"
                matInput
                class="page-form-field-line">
        </mat-form-field>
        
        <div class="btnActiveDiv" *ngIf ="showButton">  
            <span class="btnSpanSlide">Bloquear</span>
            <mat-slide-toggle class="btnActiveSlide" (change)="btnBlockInblock($event)" [disabled]='slideStatus' formControlName="pageActive" >
                Ativar
            </mat-slide-toggle>
        </div>

        <div fxLayout="row" fxLayoutAlign="end start">
            <button #submitButton [disabled]="pageInfoForm.invalid" mat-flat-button class="edit-account-button" type="submit">
                Editar
            </button>
        </div>
       
    </form>
</div>

