import { Component, OnInit, HostListener } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Plan } from "src/app/shared/interfaces/plan";
import { PlansService } from "src/app/shared/_services/plans/plans.service";
import { PartnerService } from "src/app/shared/_services/partner/partner.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertService } from "src/app/shared/dialogs/alert/alert.service";
import { PartnerEstablishmentService } from "src/app/shared/_services/partner-establishment/partner-establishment.service";

@Component({
  selector: "app-plans",
  templateUrl: "./plans.component.html",
  styleUrls: ["./plans.component.scss"],
})
export class PlansComponent implements OnInit {
  public plans: Array<Plan>;
  public selected: Plan;
  public innerWidth;
  private partnerId;
  private pageId;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private partnerService: PartnerService,
    private plansService: PlansService,
    private snackbar: MatSnackBar,
    private alertService: AlertService
  ) {
    this.getPartnerId();
    this.listPlans();
    this.innerWidth = window.innerWidth;
    this.pageId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {}

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  getPartnerId() {
    this.partnerId = this.partnerService.partner._id;
    console.log(this.partnerService.partner._id);
  }

  backHome() {
    this.router.navigate([""]);
  }

  listPlans() {
    this.plansService.getPlans().subscribe((res: any) => {
      console.log("Os planos disponíveis são: ", res.data);
      this.plans = res.data;
    });
  }

  selectPlan(p) {
    this.selected = p;
    console.log(p);
  }

  confirmPlan() {
    console.log(
      "partner:",
      this.partnerId,
      "\npage:",
      this.pageId,
      "\nplan:",
      this.selected._id
    );
    this.plansService.sign(this.pageId, this.selected._id).subscribe(
      (res: any) => {
        console.log(res);
        if (res.success) {
          this.snackbar.open("Plano asinado com sucesso", "Fechar", {
            duration: 3000,
          });
          this.router.navigate([""]);
        } else {
          this.alertService.showAlert("Erro", res.message);
        }
      },
      (err) => {
        this.alertService.showAlert("Erro", err);
      }
    );
  }
}
