import { tap } from "rxjs/operators";
import { environment } from "./../../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Partner } from '../../interfaces/partner';

const TOKEN_KEY = "access_token";

@Injectable({
  providedIn: "root",
})
export class PartnerService { 
  public url = environment.url + "/partnerauth/partner";
  public partner: Partner = {};
  public integrationsCounter;

  constructor(private http: HttpClient) {
    // this.partner.config = {}
  }

  getPartner() {
    const url = this.url + "/"
    return this.http.get(url)
  }

  editPartner(partnerObj) {
    const url = this.url + "/"
    return this.http.put(url, partnerObj);
  }
}