import { PartnerCredentials } from "./../../shared/interfaces/partner-credentials";
import { PartnerAuthService } from "./../../shared/_services/partner-auth/partner-auth.service";
import { Component, OnInit, HostListener, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { AlertComponent } from "src/app/shared/dialogs/alert/alert.component";
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/dialogs/alert/alert.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public innerWidth;
  public ball;
  public passwordFocused;
  public credentials: PartnerCredentials = {};

  constructor(
    private elem: ElementRef,
    private auth: PartnerAuthService,
    private router: Router,
    public alertService: AlertService,
  ) {
    this.ball = this.elem.nativeElement.getElementsByClassName("ball");
    this.innerWidth = window.innerWidth;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  // EYE MOVIMENT
  @HostListener("document:mousemove", ["$event"])
  onMouseMove(e) {
    if (this.innerWidth > 950) {
      var maxX = (window.innerWidth * 0.6) / 2 + 400;
      var maxY = window.innerHeight / 2 + 80;

      if (e.clientX < maxX) {
        var x = (e.clientX * 100) / window.innerWidth - 10 + "%";
      }
      if (e.clientY < maxY) {
        var y = (e.clientY * 100) / window.innerHeight - 10 + "%";
      }

      for (var i = 0; i < 2; i++) {
        this.ball[i].style.left = x;
        this.ball[i].style.top = y;
      }
    }
  }
  // END OF EYE MOVIMENT

  ngOnInit(): void {
  }

  login() {
    this.auth.login(this.credentials).subscribe(
      (res) => this.router.navigate(['/']),
      (err) => console.log("Erro ao fazer login: ", err)
    );
  }

  showHelp(){
    this.alertService.showAlert("Entre em \n contato:", "integracao@anota.ai");
  }
}
