<div mat-dialog-title class="div-delete-title">
  <div fxLayout="row" fxLayoutAlign="center center">
    <img class="img" src="../../../assets/logo-robot.svg">
    <div class="divider"></div>
    <b><span class="delete-title">Excluir</span></b>
  </div>
</div>
<div mat-dialog-content class="div-delete-content">
  <br>
  <div fxLayout="column" fxLayoutGap="12px">
    <button mat-flat-button flex="33" class="confirm-button">Confirmar</button>
    <button mat-flat-button flex="33" class="cancel-button" (click)="cancel()">Cancelar</button>
  </div>
  <br>
</div>
<div mat-dialog-actions></div>
