// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  url: "https://staging-integration-admin.api.anota-ai.com",
  // url: "https://api-integracao.anota.ai",
  // url: "http://localhost:3000",
  // url:'https://stagingchatbot-pr-940.herokuapp.com',
  // url:'https://stagingchatbot-pr-919.herokuapp.com',
  // url: 'https://stagingchatbot.herokuapp.com',
  // url:'https://stagingchatbot-pr-952.herokuapp.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
