import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModulesService } from "src/app/shared/_services/modules/modules.service";
import { AlertService } from "src/app/shared/dialogs/alert/alert.service";
import { PageModule } from "src/app/shared/interfaces/page-module";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PartnerService } from "src/app/shared/_services/partner/partner.service";

@Component({
  selector: "app-modules",
  templateUrl: "./modules.component.html",
  styleUrls: ["./modules.component.scss"],
})
export class ModulesComponent implements OnInit {
  public pageId;
  public maxDate: Date;
  public minDate: Date;
  public pageModule: PageModule = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public modulesService: ModulesService,
    private alertService: AlertService,
    private snackbar: MatSnackBar,
    private partnerService: PartnerService
  ) {
    this.pageId = this.route.snapshot.paramMap.get("id");
    this.modulesService.getModules(this.pageId);
    // this.modulesService.getModulesByPage(this.pageId);
    this.setMaxAndMinDates();
    this.partnerService.getPartner();
  }

  ngOnInit(): void {}

  setMaxAndMinDates() {
    let today = new Date().getTime();
    this.minDate = new Date(today);
    this.maxDate = new Date(today + 30 * 24 * 60 * 60 * 1000); // dia para milisegundo
  }

  backHome() {
    // this.router.navigate([""]);
    window.location.href = "https://integracao.anota.ai/";
  }
  public columns() {
    return Math.trunc((window.innerWidth - 260) / 280);
  }
  public gridTile() {
    let col = Math.trunc((window.innerWidth - 260) / 280);
    let row = Math.trunc((window.innerWidth - 260) / 280);
    return col + ":" + row;
  }

  dateChange(event, module) {
    this.pageModule.id_module = module._id;
    this.pageModule.start_date = event.value;
    this.pageModule.test = true;
    this.pageModule.price = module.base_price;
    this.pageModule.page = this.pageId;
    this.signModule(this.pageModule);
  }

  addModule(module) {
    let today = new Date().getTime();
    let date = new Date(today);
    this.pageModule.start_date = date;
    this.pageModule.id_module = module._id;
    this.pageModule.test = false;
    this.pageModule.price = module.base_price;
    this.pageModule.page = this.pageId;
    console.log(this.pageModule);
    this.signModule(this.pageModule);
  }

  cancelModule(module) {
    console.log(module);
    if (module.isTest) {
      this.modulesService
        .cancelModule(
          module._id,
          this.pageId,
          this.partnerService.partner._id,
          module.isTest
        )
        .subscribe(
          (res: any) => {
            console.log(res);
            if (res.success) {
              this.modulesService.getModules(this.pageId);
              this.snackbar.open("Módulo desativado com sucesso", "fechar", {
                duration: 3000,
              });
            } else {
              this.alertService.showAlert("Erro", res.message);
            }
          },
          (err) => {
            this.alertService.showAlert(
              "Erro",
              "Ocorreu um erro ao desativar este módulo"
            );
          }
        );
    } else {
      this.modulesService
        .cancelModule(
          module._id,
          this.pageId,
          this.partnerService.partner._id,
          module.isTest
        )
        .subscribe(
          (res: any) => {
            console.log(res);
            if (res.success) {
              this.alertService.showAlert(
                "Atenção",
                "Sua solicitação de cancelamento foi recebida. Após analisada, você receberá uma resposta por email."
              );
            } else {
              this.alertService.showAlert("Erro", res.message);
            }
          },
          (err) => {
            this.alertService.showAlert(
              "Erro",
              "Ocorreu um erro ao desativar este módulo"
            );
          }
        );
    }
  }

  showInfo(title, text) {
    this.alertService.showAlert("Info", "Módulo: " + title + ":\n " + text);
  }

  signModule(pageModule) {
    console.log(pageModule);
    this.modulesService.signModule(pageModule).subscribe(
      (res: any) => {
        console.log(res);
        if (res.success) {
          this.modulesService.getModules(this.pageId);
          if (pageModule.test) {
            let year = pageModule.start_date.getFullYear();
            let month = pageModule.start_date.getMonth() + 1;
            let day = pageModule.start_date.getDate();
            if (month < 10) {
              month = "0" + month;
            }
            let date = day + "/" + month + "/" + year;
            this.alertService.showAlert(
              "Atenção",
              "Este módulo começará a ser cobrado no dia " + date
            );
            this.snackbar.open("Teste ativado até o dia " + date, "fechar", {
              duration: 3000,
            });
          } else {
            this.snackbar.open("Módulo assinado com sucesso", "fechar", {
              duration: 3000,
            });
          }
        } else {
          this.alertService.showAlert("Erro", res.message);
        }
      },
      (err) => {
        this.alertService.showAlert(
          "Erro",
          "Ocorreu um erro ao ativar este módulo"
        );
      }
    );
  }
}
