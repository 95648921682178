<div class="card-retangle" fxLayout="column" fxLayoutGap="20px">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="25px"
    class="title">
    <mat-icon class="icon">{{mat_icon}}</mat-icon>
    <span class="text">{{title}}</span>
  </div>
  <div class="price-form">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px" fxFill>
      <span class="brl">R$</span>
      <span class="price">{{price}}</span>
      <!-- <span class="cycle">p/ mês</span> -->
    </div>
  </div>
  <div
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="15px"
    class="details">
    <div class="period">
      <span>Por {{period}} meses</span>
    </div>
    <div *ngFor="let d of description"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
      class="detail">
      <mat-icon class="icon">check</mat-icon>
      <span class="text">{{d}}</span>
    </div>
  </div>
  <div class="divider"></div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <button mat-stroked-button class="select-button">Selecionar</button>
  </div>
</div>
